<template>
  <b-sidebar
    id="add-new-state-sidebar"
    :visible="isAddNewStateSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-state-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">{{ $t("Add") }}  {{ $t('New') }} City</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group :label="$t('Form.Name')" label-for="name">
              <b-form-input
                id="name"
                v-model="stateData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Texas"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- State -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group label="State" label-for="name">
              <v-select
                v-model="stateData.states_id"
                :state="getValidationState(validationContext)"
                class="w-100"
                :options="stateOptions"
                :reduce="(value) => value.id"
                label="name"
                @search="searchState"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- status -->
          <validation-provider
            #default="validationContext"
            name="Status"
            rules="required"
          >
            <b-form-group :label="$t('Form.Status')" label-for="status">
              <v-select
                id="status"
                v-model="stateData.status"
                :state="getValidationState(validationContext)"
                :options="optionsStatus"
                :reduce="(value) => value.value"
                label="text"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="loading"
            >
              <span v-if="!loading">{{ $t("Add") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { onMounted, ref } from "@vue/composition-api";

import axiosS from "@/services/admin/cities";
import axiosSC from "@/services/admin/state";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewStateSidebarActive",
    event: "update:is-add-new-state-sidebar-activ",
  },
  props: {
    isAddNewStateSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const optionsStatus = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];
    const stateDataTem = {
      name: "",
      status: true,
      states_id: "",
    };
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));

    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    onMounted(() => {
      getStates();
    });

    const stateOptions = ref([]);
    const getStates = () => {
      axiosSC.stateList(20).then(({ data }) => {
        stateOptions.value = data;
      });
    };

    const setTimeoutBuscador = ref('')
    const searchState = (state, loading) => {
      if (state.length) {
        loading(true);
        clearTimeout(setTimeoutBuscador.value);
        const datos = {
          name: state,
        };
        setTimeoutBuscador.value = setTimeout(() => {
          axiosSC.stateFilterFilters(datos).then(({ data }) => {
            loading(false);
            stateOptions.value = data;
          });
        }, 350);
      }
    }

    const loading = ref(false);
    const onSubmit = () => {
      loading.value = true;
      const datos = {
        ...stateData.value,
      };
      axiosS
        .citiesCreate(datos)
        .then(() => {
          loading.value = false;
          resetuserData();
          emit("createState", true);
        })
        .catch(() => {
          loading.value = false;
          emit("createState", false);
        });
    };
    return {
      loading,
      stateData,
      optionsStatus,
      stateOptions,
      searchState,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style>
</style>