<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>{{ $t("Filters") }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>{{ $t("message.tableHeader.name") }}</label>
          <b-form-input
            v-model.trim="filters.name"
            class="w-100"
            :reduce="(val) => val.value"
            @keyup.enter="searchFilter"
          />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>State</label>
          <v-select
            v-model="filters.states_id"
            class="w-100"
            :options="stateOptions"
            label="name"
            @search="searchState"
            @keyup.enter="searchFilter"
          />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>{{ $t('Form.Status') }}</label>
          <v-select
            v-model="filters.status"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @keyup.enter="searchFilter"
            label="text"
          />
        </b-col>
        <b-col cols="12" class="mb-md-0 mb-2 mt-2" align="end">
          <b-button variant="primary" @click="searchFilter" :disabled="loading">
            <span v-if="!loading">{{ $t("Search") }}</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axiosS from "@/services/admin/state";

import SpinnerLoading from "@/components/SpinnerLoading";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BButton,
    vSelect,
    SpinnerLoading,
  },
  props: {
    statusOptions: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      setTimeoutBuscador: "",
      stateOptions: [],
      filters: {
        name: "",
        status: "",
        states_id: null,
      },
    };
  },
  methods: {
    searchFilter() {
      const data = this.filters;
      this.$emit("searchFilter", JSON.parse(JSON.stringify(data)));
      const { states_id, name, status } = this.filters;
      if (states_id == null && name == "" && status == "") {
        this.$emit("filterPage", false);
      } else {
        this.$emit("filterPage", true);
      }
    },
    searchState(state, loading) {
      if (state.length) {
        loading(true);
        clearTimeout(this.setTimeoutBuscador);
        const datos = {
          name: state,
        };
        this.setTimeoutBuscador = setTimeout(() => {
          axiosS.stateFilterFilters(10, datos).then(({ data }) => {
            loading(false);
            this.stateOptions = data;
          });
        }, 350);
      }
    },
  },
};
</script>

<style>
</style>
