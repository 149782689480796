<template>
  <div>
    <CitiesListFilters
      v-if="$can('filter', 'module_system_settings.settings.admin_facility.cities')"
      :statusOptions="statusOptions"
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    />
    <b-card>
      <TitleTable titleTable="Cities" iconTable="MapIcon" />
      <TableCityAdd
        :is-add-new-state-sidebar-active.sync="isAddNewStateSidebarActive"
        @createState="createState"
      />
      <TableCityEdit
        v-if="isEditCitySidebarActive"
        :is-edit-city-sidebar-active.sync="isEditCitySidebarActive"
        :itemEdit="itemEdit"
        @isEditCity="isEditCity"
      />
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Entries") }}</label>
          </b-col>

          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
                variant="primary"
                @click="isAddNewStateSidebarActive = true"
                :disabled="loading"
                v-if="$can('store', 'module_system_settings.settings.admin_facility.cities')"
              >
                <span v-if="!loading" class="text-nowrap">{{ $t("Add") }} City</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        outlined
        bordered
        class="position-relative"
        responsive
        :items="items"
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        :sort-by.sync="sortBy"
        :sort-asc.sync="isSortDirDesc"
      >
        <template #cell(state)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.states.name
            }}</span>
          </div>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.status ? $t("Active") : $t("Inactive")
            }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-b-tooltip.hover.top="'Edit'"
            v-if="$can('update', 'module_system_settings.settings.admin_facility.cities')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            @click="
              editCity(data.item);
            "
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            v-b-tooltip.hover.top="'Change Status'"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-warning"
            class="btn-icon"
            @click="changeCity(data.item)"
          >
            <feather-icon icon="RefreshCcwIcon" />
          </b-button>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BFormInput,
  BCol,
  BRow,
  BButton,
  BPagination,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import CitiesListFilters from "@/views/admin/states-cities/cities/CitiesListFilters";
import TableCityAdd from "./TableCityAdd.vue";
import TableCityEdit from "./TableCityEdit.vue";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";
import PaginationTable from "@/components/PaginationTable";

import axiosC from "@/services/admin/cities";
import { ref } from "@vue/composition-api";

export default {
  components: {
    BTable,
    BCard,
    BFormInput,
    BCol,
    BRow,
    BButton,
    BPagination,
    vSelect,
    ToastNotification,
    TableCityAdd,
    TableCityEdit,
    TitleTable,
    CitiesListFilters,
    SpinnerLoading,
    GoBack,
    PaginationTable,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mounted() {
    this.getCities();
  },
  watch: {
    perPage(newVal) {
      this.getCities(newVal);
    },
  },
  data() {
    return {
      tableColumns: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        { key: "state", sortable: true },
        { key: "status", sortable: true },
        { key: "actions" },
      ],
      items: [],
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      sortBy: "id",
      isSortDirDesc: true,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      currentPage: {
        page: 1,
      },
      totalUsers: 0,
      isAddNewStateSidebarActive: false,
      statusOptions: [
        { text: "Active", value: "true" },
        { text: "Inactive", value: "false" },
      ],
      filtro: false,
      loading: false,
      citiesFilter: null,
      isEditCitySidebarActive: false,
      itemEdit: null,
    };
  },
  methods: {
    filterPage(value) {
      this.filtro = value;
    },
    getCities(paginate = 10) {
      axiosC
        .citiesList(paginate)
        .then(({ data, total, current_page, ...res }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosC
          .citiesFilterPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosC
          .citiesFilterFiltersPagination(page, this.citiesFilter)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    createState(value) {
      if (value) {
        this.$refs.toast.success("State added successfully");
        this.isAddNewStateSidebarActive = false;
      } else {
        this.$refs.toast.danger("State not added");
        this.isAddNewStateSidebarActive = false;
      }
      this.pageChanged(this.currentPage.page);
    },
    searchFilter(value) {
      this.loading = true;
      this.citiesFilter = value;
      axiosC
        .citiesFilterFilters(value)
        .then(({ data, total, current_page, ...res }) => {
          this.loading = false;
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    editCity(item) {
      this.isEditCitySidebarActive = true;
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
    },
    isEditCity(value) {
      if (value) {
        this.isEditCitySidebarActive = false;
        this.$refs.toast.success("City updated successfully");
        this.pageChanged(this.currentPage.page);
      } else {
        this.$refs.toast.error("Error updating type ailment");
        this.$refs.toast.danger("Error updating city");
      }
    },
    changeCity(item) {
      axiosC
        .citiesUpdate(item.id, { ...item, status: !item.status })
        .then(() => {
          this.pageChanged(this.currentPage.page);
          this.$refs.toast.success("State changed successfully");
        })
        .catch(() => {
          this.$refs.toast.danger("State not changed");
        });
    },
  },
};
</script>